import { ref } from 'vue'
import { defineStore } from 'pinia'
import { queryTenantTreeList, queryTenantTopOrg } from '@/api'
import { CODE_ENUM } from '@/common'

export const useTenantStore = defineStore('tenant', () => {
  const tenantList: any = ref([])
  const queryTenant = async () => {
    const res: any = await queryTenantTreeList()
    if (res?.code === CODE_ENUM.SUCCESS) {
      tenantList.value = res.data || []
    }
  }

  const topOrgId = ref('')
  const topOrgInfo: any = ref({})
  const queryTopOrg = async () => {
    const res: any = await queryTenantTopOrg()
    if (res?.code === CODE_ENUM.SUCCESS) {
      topOrgInfo.value = res.data
      topOrgId.value = res.data.orgId
    }
  }

  return { tenantList, queryTenant, topOrgId, topOrgInfo, queryTopOrg }
})
